import React, { useState } from 'react'
import '../css/navbar.css'
import Winners from '../winners';
import { useNavigate } from 'react-router-dom';

const Navbar = () => {
    const [isNavExpanded, setIsNavExpanded] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const navigate = useNavigate();

    const openModal = () => {
        setIsOpen(true);
    }

    const closeModal = () => {
        setIsOpen(false);
    }

    const onLogout = () => {
        localStorage.clear();
        window.location.href = "/"
    }

    const token = localStorage.getItem('token');

    return (
      <>
        <nav className="navigation">
          <a href="/" className="brand-name">
            <img src="/images/uv_games_logo.png" alt="UV Games Logo" />
          </a>
          <div>
            {/* {!!token && ( */}
            <div className = "mobile-icons">
              <button
                className="mobile-android-button winners_button"
                onClick={() => navigate('/allGames/Android')}
              >
                <i className="fa fa-android"></i>
              </button>
              <button
                className="mobile-winner-button winners_button"
                onClick={openModal}
              >
                <i className="fa-solid fa-trophy"></i>
              </button>
            </div>
           
            {/* )} */}
            <button
              className="hamburger"
              onClick={() => {
                setIsNavExpanded(!isNavExpanded);
              }}
            >
              <i className="fa-solid fa-bars"></i>
            </button>
          </div>

          <div
            className={
              isNavExpanded ? "navigation-menu expanded" : "navigation-menu"
            }
          >
            <ul>
              <li>
                <a href="/allGames/Sports">Sports</a>
              </li>
              <li>
                <a href="/allGames/Funny">Funny</a>
              </li>
              <li>
                <a href="/allGames/Action">Action</a>
              </li>
              <li>
                <a href="/allGames/Adventure">Adventure</a>
              </li>
              <li>
                <a href="/allGames/Racing">Racing</a>
              </li>
              <li>
                <a href="/allGames/Kids">Kids</a>
              </li>
              <li>
                <a href="/allGames/Sports">Puzzle</a>
              </li>
              <li className = "mobile-category">
                <a href="/allGames/Android">Android Games</a>
              </li>
              {/* <li>
                            <a href="/allGames/Sports">Arcade</a>
                        </li> */}

              <>
                <li>
                  {/* <a href="/allGames/Android" className="brand-name"> */}
                    <button className="winners_button tabletdesktop-winner-button" onClick={() => navigate('/allGames/Android')}>
                      <i className="fa fa-android"></i> Android Games
                    </button>
                  {/* </a> */}
                </li>
                <li>
                  <button
                    className="winners_button tabletdesktop-winner-button"
                    onClick={openModal}
                  >
                    <i className="fa-solid fa-trophy"></i> Winners
                  </button>
                </li>

                {!!token && (
                  <li>
                    <button className="logout_button tabletdesktop-logout-button" onClick={onLogout}>
                      <i className="fas fa-sign-out"></i> Logout
                    </button>
                  </li>
                )}
              </>
            </ul>
          </div>
        </nav>
        {/* {!!token && <Winners isOpen={isOpen} closeModal={closeModal} />} */}
        <Winners isOpen={isOpen} closeModal={closeModal} />
      </>
    );
}

export default Navbar