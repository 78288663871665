import axios from "axios";
import baseUrl from "../utils/baseUrl";

export const loginApi = async (userData) => {
    try {
        const response = await axios.post(`${baseUrl}/user/adminLogin`, userData);
        return response.data.data.token;
    } catch (e) {
        throw e.response.data;
    }
};

export const sendOtp = async (data) => {
    try {
        const response = await axios.post(`${baseUrl}/user/sendOtp`, data);
        return response.data.message;
    } catch (e) {
        throw e.response.data;
    }
};

export const login = async (data) => {
    try {
        const response = await axios.post(`${baseUrl}/user/verifyOtp`, data);
        return response.data;
    } catch (e) {
        throw e.response.data;
    }
};