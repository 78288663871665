import React from 'react';
import { Navigate, Route, Routes } from "react-router-dom";
import Home from '../components/home';
import Login from "../components/login/login";


const OpenRoute = () => {

    return (
        <Routes>
            <Route path='/' element={<Home/>} /> 
            <Route path='/home' element={<Home/>} /> 
            <Route path='/login' element={<Login/>}  />
            <Route path='*' element={<Navigate to="/login" />} />
        </Routes>
    )

};

export default OpenRoute;