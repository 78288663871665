export const brandImages = [
    "/images/brand_1.png",
    "/images/brand_2.png",
    "/images/brand_3.png",
    "/images/brand_4.png",
    "/images/brand_2.png",
    "/images/brand_5.png",
  ];

export const footerImages = [
      "/images/footer_3.png",
      "/images/footer_2.png",
      "/images/footer_1.png",
      "/images/footer_4.png",
      "/images/footer_5.png",
  ];