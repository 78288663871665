import React, { useState, useEffect } from 'react'
import '../css/style.css';
import '../css/login1.css';
import { NavLink } from 'react-router-dom';
import ReactCustomFlagSelect from 'react-custom-flag-select';
import "react-custom-flag-select/lib/react-custom-flag-select.min.css";
import OtpInput from 'react-otp-input';
import { useNavigate } from 'react-router-dom';
import {sendOtp, login} from '../../services/auth-api';
// import { useTranslation } from 'react-i18next';

const Login = () => {
    // const { t } = useTranslation();
    const navigate = useNavigate();
    const [otp, setOtp] = useState('');
    const [ButtonDisabled, setButtonDisabled] = useState(false);
    const [areaCode, setAreaCode] = useState(1); // Initialize with your default value
    const [phone, setPhone] = useState({});
    const [currentItem, setCurrentItem] = useState({ id: areaCode }); // Initialize with your default value
    const [message, setMessage] = useState({
        message: "",
        error: false
    });
    const find = (arr, obj) => {
        return arr.filter(o => Object.keys(obj).every(i => obj[i] === o[i]));
      };
      
      const FLAG_SELECTOR_OPTION_LIST = [
        // { id: '91', name: 'IND', displayText: 'IND (+91)', locale: 'en-US', englishName: 'India', flag: '/images/flags/BT.png' },
        // { id: '1', name: 'US', displayText: 'US(+1)', locale: 'en-US', englishName: 'United State', flag: '/images/flags/US.png' },
        { id: '232', name: 'SL', displayText: 'US(+232)', locale: 'en-US', englishName: 'United State', flag: '/images/flags/SL.png' }
      ];
      const singleDataItem = FLAG_SELECTOR_OPTION_LIST[0];
      const validatePhoneNumber = (phoneNumber) => {
        // Define a regular expression for a typical 10-digit US phone number
        const phoneRegex = /^\d{10}$/;
      
        // Test the provided phone number against the regular expression
        return phoneRegex.test(phoneNumber);
      }

      const sendOtpUser = async() => {
        if(validatePhoneNumber(phone)){
            await sendOtp({mobileNumber: phone}).then((res) => {
                console.log(res)
                setMessage({
                    message: res,
                    error: false
                })
                //setHistoryList(res)
            }).catch((err) => {
                console.log(err)
            })
        }else{
            setMessage({
                message: "Phone Number is not valid",
                error: true
            })
        }
      }
    

      const handlePhoneChange = (newPhone) => {
        // Handle phone change logic here
      };
    
    //   const handleAreaCodeChange = (newAreaCode) => {
    //     setAreaCode(newAreaCode);
    //     const [newCurrentItem] = FLAG_SELECTOR_OPTION_LIST.filter(item => item.id === newAreaCode);
    //     setCurrentItem(newCurrentItem);
    //     handlePhoneChange(phone);
    //   };

      const handleNumInputsChange = (numInputs) => {
        setOtp(numInputs);
        setButtonDisabled(numInputs.length !== 4 || isNaN(numInputs));
      };
      
      const handleButtonClick = async() => {
        console.log(areaCode);
        console.log(phone);
        console.log(otp);
        await login({mobileNumber: parseInt(phone), otp: parseInt(otp)}).then((res) => {
            console.log(res)
            localStorage.setItem("token", res.data.token);
            //console.log("path",localStorage.getItem("menu")?"/"+localStorage.getItem("menu"):"/");
            //navigate('/')
            window.location.href = localStorage.getItem("menu")?"/"+localStorage.getItem("menu"):"/"
            //setHistoryList(res)
        }).catch((err) => {
            console.log(err)
            setMessage({
                message: err.error,
                error: true
            })
        })
       // navigate('/profile');
      };

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
          navigate("/");
        }
    }, []);

    return (
        <div className='home'>
            <div className="maine-div">
                <div className="container-fluid box-wrapper login"> 
                    <div className='pt-5'>
                        <div className="login-thumbnail">
                            <img src="/images/uv_games_logo.png" alt="UVGames" />
                        </div>
                    </div>
                    <div className="login-header pb-4">
                        <div className="box-wrapper mx-auto text-center position-relative"> 
                            <span className="modal-level-title game-title">Welcome to UVGames</span>
                        </div>
                    </div>
                    <div className="login-info">
                        <div className="login-thumbnail2">
                            <img src="/images/login_img.png" alt="UVGames" />
                        </div>
                        <h3 className="login-title mb-3 mt-3 text-center">Login to your account</h3>
                        {
                            message.message && <div class="mb-3">
                            <span className={`alert alert-${message.error ? 'danger' : 'success'}`}>{message.message}</span>
                            </div>
                        }
                        
                        <div className="phone-info">
                    <div className="phone-number-info">

                    
                    {/* <ReactCustomFlagSelect
                        attributesWrapper={{ id: 'areaCodeWrapper', tabIndex: '1' }}
                        attributesButton={{ id: 'areaCodeButton' }}
                        attributesInput={{ id: 'areaCode', name: 'areaCode' }}
                        value={singleDataItem.id}
                        disabled={true}
                        showSearch={false}
                        fields={['name', 'locale', 'displayText', 'englishName']}
                        showArrow={false}
                        animate={true}
                        optionList={[singleDataItem]}
                        classNameDropdownIconOptionListItem={''}
                        customStyleWrapper={{}}
                        customStyleContainer={{ border: 'none', fontSize: '12px' }}
                        customStyleSelect={{ width: '100px' }}
                        customStyleOptionListItem={{}}
                        customStyleOptionListContainer={{ maxHeight: '100px', overflow: 'auto', width: '140px', marginTop: '11px' }}
                        // onChange={handleAreaCodeChange}
                        /> */}
                        <input
                            
                            className="form-control form-mobile-no" 
                            type="tel" 
                            inputMode="numeric" 
                            onChange={(event) => {
                                    setPhone(event.target.value);
                              }}
                              placeholder='Enter Mobile Number'
                        />
                        
                        </div>
                        <div className="send_otp">
                                <NavLink to='javascript:void(0)' className="send_otp_link" onClick={ () => sendOtpUser()}>
                                Send OTP
                                </NavLink>
                        </div>

                        <p className="Verify_otp">Verify OTP</p>

                        <div className="otp_section">
               
                        <OtpInput
                            value={otp}
                            numInputs={4}
                            renderInput={(props, index) => (
                                <input
                                    {...props}
                                    type="tel"  // Use type="tel" to display the numeric keypad on mobile devices
                                    inputMode="numeric"  // Specify the numeric input mode
                                />
                            )}
                            inputStyle="form-control form-otp"
                            onChange={handleNumInputsChange}
                            />
                        </div>

                        <button className="btn btn-primary btn-pm-custom" type="button" onClick={handleButtonClick} disabled={ButtonDisabled}>Login</button>

                        <div className="resend_otp">
                        Didn’t receive code ? <NavLink to='/#' className="send_otp_link" > Resend</NavLink>
                        </div>
                    </div>
                    </div>

                    
                </div>
            </div>
        </div>
    )
}

export default Login