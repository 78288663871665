import api from "./interceptor";
import baseUrl from "../utils/baseUrl";
const token = localStorage.getItem('token')

export const getRanking = async () => {
    try {
        const response = await api.get(`${baseUrl}/user/list-user-time`,
            {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
        return response.data.data
    } catch (e) {
        throw e.response.data
    }
}
